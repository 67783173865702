<template>
    <div class="industry-cover5 cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <p class="text-white mx-5 my-5">
                        Тази услуга цели да осигури максимален комфорт и удобство на нашите клиенти. Не мислете откъде и как да набавите необходимите хигиенни консумативи &ndash; ние ще се погрижим. Професионалният ни опит ни дава възможност да работим с доказани производители и сертифицирани за безопасност продукти.    
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "IndustryCover5"
    }
</script>