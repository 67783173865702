<template>
    <div class="industry-cover2 cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row justify-content-end mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <p class="text-white mx-5 my-5">
                        Дейността обхваща широк диапазон от услуги в областта на комплексното <strong>ежедневно почистване</strong>. На базата на предварително уговорените с клиента изисквания, извършваме всичко в удобно време &ndash; преди началото или след края на работния ден. При необходимост и след предварителна заявка, осигуряваме целодневно присъствие на дежурен хигиенист за почистване.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "IndustryCover2"
    }
</script>