<template>
    <div class="industry-cover6 cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row justify-content-end mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <p class="text-white mx-5 my-5">
                        Освен стандартните почистващи услуги, имаме капацитета да поемем проекти за височинно почистване, свързани с използване на професионално оборудване - скелета, вишки и друга специализирана техника. Предлагаме както еднократна, така и абонаментна услуга за почистване на Вашите витрини, фасади, стъклени врати и повърхности. Благодарение на висококачествената технология, която използваме и обучените ни специалисти, гарантираме впечатляващи резултати.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "IndustryCover6"
    }
</script>