<template>
    <div class="industry">
        <IndustryCover />
        <div class="industry-nav container my-5">
            <div class="row">
                <div class="col mb-5 mb-md-0">
                    <span class="d-flex bump align-items-center">
                        <a href="#ind1">
                            <h4>Ежедневно почистване</h4>
                        </a>
                    </span>
                </div>
                <div class="col mb-5 mb-md-0">
                    <span class="d-flex bump align-items-center">
                        <a href="#ind2">
                            <h4>ПЕРИОДИЧНО почистване</h4>
                        </a>
                    </span>
                </div>
                <div class="col mb-5 mb-md-0">
                    <span class="d-flex bump align-items-center">
                        <a href="#ind3">
                            <h4>Техническа поддръжка</h4>
                        </a>
                    </span>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col mb-5 mb-md-0">
                    <span class="d-flex bump align-items-center">
                        <a href="#ind4">
                            <h4>Доставка на консумативи</h4>
                        </a>
                    </span>
                </div><div class="col mb-5 mb-md-0">
                    <span class="d-flex bump align-items-center">
                        <a href="#ind5">
                            <h4>Витрини и фасади</h4> 
                        </a>
                    </span>
                </div>
                <div class="col mb-5 mb-md-0">
                    <span class="d-flex bump align-items-center">
                        <a href="#ind6">
                            <h4>Допълнителни услуги</h4>
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <IndustryCover2 id="ind1"/>
        <div class="container mt-5 px-2">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h3 class="big-title mt-2">
                    Ежедневно почистване
                    <br>
                    Зареждане и проследяване за наличието на хигиенни консумативи <br> и почистващи препарати.
                    </h3>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="add-services flex flex-row mt-3">
                        <h3>Ежедневното <router-link to="/">почистване</router-link> включва</h3>
                        <ul class="one-col">
                            <li>Измиване на твърди подови настилки</li>
                            <li>Прахосмучене на меки подови настилки</li>
                            <li>Избърсване и полиране на бюра, офис мебели и др.</li>
                            <li>Почистване на офис техника /компютри, монитори, клавиатури, принтери и др./</li>
                            <li>Избърсване на врати, стъклени преградни стени, парапети.</li>
                            <li>Периодично почистване на стъклени повърхности, осветителни тела и др.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <p>
                    Периодично почистване на прозорци и дограма&nbsp;според предварително договорените условия. Ежедневното почистване е най-ефективният метод за поддържане на хигиена в офиса.&nbsp;
                </p>
            </div>
        </div>
        <IndustryCover3 id="ind2" />
        <div class="container mt-5 px-2">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h3 class="big-title mt-2">
                    Поддръжка на всички<br> повърхности
                    <br>
                    и запазване на тяхното<br> качеството&nbsp;
                    </h3>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="add-services flex flex-row mt-3">
                        <h3>Включва</h3>
                        <ul class="one-col">
                            <li>Машинно изпиране на меки подови настилки</li>
                            <li>Машинно изпиране на тапицерия и мека мебел</li>
                            <li>Основно почистване на кухненски и сервизни помещения</li>
                            <li>Основно почистване и дезинфекция на санитарни помещения</li>
                            <li>Измиване на прозорци и дограма, стъклени преградни стени</li>
                            <li>Почистване на осветителни тела, климатични и вентилационни отвори</li>
                            <li>Почистване на окачени тавани</li>
                            <li>Измиване на вертикални и хоризонтални щори, външни решетки</li>
                            <li>Почистване на фасади и труднодостъпни места по алпийски способ</li>
                            <li>Почистване на рекламни табели</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <IndustryCover4 id="ind3" />
        <div class="container mt-5 px-2">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h3 class="big-title mt-2">
                    Професионални техници провеждат ежемесечна профилактика във Вашия офис, осигуряват поддръжка и предотвратяват бъдещи аварии.
                    </h3>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="add-services flex flex-row mt-3">
                        <h3>Включва поддръжка и ремонт на:</h3>
                        <ul class="one-col">
                            <li>ЕЛЕКТРИЧЕСКАТА МРЕЖА</li>
                            <li>ВИК ИНСТАЛАЦИЯ</li>
                            <li>ОТОПЛИТЕЛНИТЕ СИСТЕМИ</li>
                            <li>ВРАТИ, ПРОЗОРЦИ И ЩОРИ</li>
                            <li>МЕБЕЛИРОВКА</li>
                            <li>ПОДОВИ НАСТИЛКИ, СТЕНИ И ТАВАНИ</li>
                            <li>БОЯДЖИЙСКИ УСЛУГИ</li>
                            <li>КЪРТЕНЕ И ПРОБИВАНЕ</li>
                            <li>ДРЕБНИ РЕМОНТИ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <IndustryCover5 id="ind4" />
        <div class="container mt-5 px-2">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="add-services flex flex-row mt-3">
                        <h3>Доставяме:</h3>
                        <ul class="one-col">
                            <li>Течен сапун</li>
                            <li>Тоалетна хартия</li>
                            <li>Кухненска хартия</li>
                            <li>Нагънати кърпи</li>
                            <li>Чували</li>
                            <li>WC ароматизатори</li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="add-services flex flex-row mt-3">
                        <h3>Как работи:</h3>
                        <ul class="one-col">
                            <li>Заявете услугата чрез формата за запитване или на телефон 087 630 5600</li>
                            <li>Предлагаме опция за еднократна доставка или абонаментна услуга</li>
                            <li>Доставката е безплатна и се извършва до адрес, в предварително уговорен час</li>
                            <li>Времето за доставка е до 3 дни от заявката</li>
                            <li>Минималната стойност на заявката е 40 лв.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <IndustryCover6 id="ind5" />
        <div class="container mt-5 px-2">
            <p>
                За проекти, включващи труднодостъпни пространства, работим с професионални алпинисти. Обучени и с дългогодишен опит, те могат да отстранят всякакви замърсявания без значение от височината, спазвайки всички съвременни изисквания за безопасност.
                <br />
                Работим по модерни методи, високи стандарти за качество на услугата и разполагаме с капацитет за почистване на замърсени фасадни повърхности с различна архитектура.<br />
                <br />
                В зависимост от степента на замърсяване, вида на фасадата и нейната облицовка, избираме най-подходящите препарати и прилагаме различни техники за почистване.<br />
                <br />
                Поради спецификата на всеки проект, при необходимост, наш специалист ще направи предварителен оглед. За нас е особено важно да разберем повече за характеристиките на обекта &ndash; квадратура, повърхност, височина, степен на замърсеност и други. В резултат, можем да Ви подадем коректна информация и да калкулираме най-добрата цена.<br />
                <br />
            </p>
        </div>
    </div>
</template>

<script>
    import IndustryCover from "./includes/IndustryCover"
    import IndustryCover2 from "./includes/IndustryCover2"
    import IndustryCover3 from "./includes/IndustryCover3"
    import IndustryCover4 from "./includes/IndustryCover4"
    import IndustryCover5 from "./includes/IndustryCover5"
    import IndustryCover6 from "./includes/IndustryCover6"

    export default {
        name: "Industry",
        components: {
            IndustryCover,
            IndustryCover2,
            IndustryCover3,
            IndustryCover4,
            IndustryCover5,
            IndustryCover6
        }
    }
</script>