<template>
    <div class="industry-cover4 cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row justify-content-end mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <p class="text-white mx-5 my-5">
                        Спокойствието на клиентите е от изключително значение за нас, затова осигуряваме услугата техническа поддръжка. Тя включва ежемесечна профилактика, а при възникнала авария &ndash; набавяне на резервни части и ремонт в момента на огледа или в удобно за Вас време. Техническият ни екип ще е при Вас до 2 часа след подаване на сигнала, 24 часа в денонощието.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "IndustryCover4"
    }
</script>