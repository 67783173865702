<template>
    <div class="industry-cover3 cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <p class="text-white mx-5 my-5">
                        Като отделна услуга или в допълнение към абонаментното почистване, можем да Ви предложим основно почистване на всякакъв тип помещения. Почистването може да се извърши както след ремонтни и строителни дейнoсти, така и освежаващо през годината.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "IndustryCover3"
    }
</script>