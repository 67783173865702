<template>
    <div class="industry-cover cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">ЗА Индустриални обекти</h4>
                    <p class="text-white mx-5 my-4">
                        Екипът на фирма за почистване Cleaner от квалифицирани хигиенисти е специализиран в работата с търговски комплекси, бизнес сгради, производствени зони, вериги магазини, банки, шоуруми, супер- и хипермаркети в цялата страна.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "IndustryCover"
    }
</script>